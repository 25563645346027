




/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import 'zone.js';
import 'zone.js/testing';



/***************************************************************************************************
 * APPLICATION IMPORTS
 */
import '@angular/localize/init'